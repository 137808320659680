import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./component/Header";
import Hero from "./component/Hero";
import About from "./component/About";
import Teams from "./component/Teams";
import Services from "./component/Services";
import Scale from "./component/Scale";
import Community from "./component/Community";
import BuildCommunity from "./component/BuildCommunity";
import News from "./component/News";
import Join from "./component/Join";
import Footer from "./component/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import Roadmap from "./component/Roadmap";
function App() {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <>
      <div className="main-hero" id="home">
        <Header />
        <Hero />
      </div>
      <div className="main-about">
        <About />
        {/* <Teams /> */}
      </div>
      <div className="main-about">
        <Services />
      </div>
      <div className="main-about">
        <Scale />
        <Community />
      </div>
      <div className="main-about">
        <BuildCommunity />
        {/* <News /> */}
      </div>
      <div className="main-about">
        <Roadmap />
      </div>
      <Join />
      <div className="main-about">
        <Footer />
      </div>
    </>
  );
}

export default App;
