import React from "react";

const Services = () => {
  return (
    <div id="services" className="services" id="services" style={{overflow:"hidden"}}>
      <div className="">
        <div className="row align-items-center">
          <div className="col-lg-4 mb-5 mt-5 mt-md-5 mb-md-5  d-flex align-items-center justify-content-center flex-column"  data-aos="fade-right" data-aos-duration="1500">
            <div className="">

            <h2>
              VOLREX <br />
              FEATURES
            </h2>
            {/* <button className="gradient-button me-5">see more</button> */}
            </div>
          </div>
          <div className="col-lg-8"    data-aos="fade-left" data-aos-duration="1500">
            <div className="row">
              <div className="col-lg-4  px-0">
                <div className="card-cus">
                  <img className="mb-4" src="\images\Focus.svg" alt="Focus" />
                  <h3>Hybrid Consensus</h3>
                  <p>
                  Combines the robustness of PoW with the efficiency of PoS. Physical asset verification ensures real-world integration and trust.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 px-0">
                <div className="card-cus">
                  <img
                    className="mb-4"
                    src="\images\Mechanism.svg"
                    alt="Mechanism"
                  />
                  <h3>Advanced Scalability</h3>
                  <p>
                   Uses layer 2 protocols to enhance transaction speeds and reduce network congestion..
                  </p>
                </div>
              </div>
              <div className="col-lg-4 px-0">
                <div className="card-cus">
                  <img
                    className="mb-4"
                    src="\images\Scalability.svg"
                    alt="Scalability"
                  />
                  <h3>AI-Driven</h3>
                  <p>
                  Enables predictive analytics and intelligent automation for smart contracts and dApps.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 px-0">
                <div className="card-cus">
                  <img
                    className="mb-4"
                    src="\images\Functionality.svg"
                    alt="Functionality"
                  />
                  <h3>Near-Zero Fees</h3>
                  <p>
                  Transaction fees as low as $0.001, fostering accessibility and affordability.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 px-0">
                <div className="card-cus">
                  <img
                    className="mb-4"
                    src="\images\Scalability.svg"
                    alt="Scalability"
                  />
                  <h3>Interoperability</h3>
                  <p>
                  IBC (Inter-Blockchain Communication) protocol ensures seamless cross-chain functionality.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 px-0">
                <div className="card-cus">
                  <img
                    className="mb-4"
                    src="\images\Functionality.svg"
                    alt="Scalability"
                  />
                  <h3> Smart Contracts</h3>
                  <p>
                  Advanced automation for industries like finance, healthcare, and logistics.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 px-0">
                <div className="card-cus">
                  <img
                    className="mb-4"
                    src="\images\Scalability.svg"
                    alt="Scalability"
                  />
                  <h3>Decentralized Governance</h3>
                  <p>
                  Community-driven decisions for transparency and inclusive upgrades.
                  </p>
                </div>
              </div>
              {/* <div className="col-lg-4 px-0">
                <div className="card-cus">
                  <img
                    className="mb-4"
                    src="\images\Functionality.svg"
                    alt="Scalability"
                  />
                  <h3>Functionality</h3>
                  <p>
                    Primarily designed for secure, decentralized financial
                    transactions. Enhanced support for complex applications and
                    advanced features.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 px-0">
                <div className="card-cus">
                  <img
                    className="mb-4"
                    src="\images\Functionality.svg"
                    alt="Scalability"
                  />
                  <h3>Functionality</h3>
                  <p>
                    Primarily designed for secure, decentralized financial
                    transactions. Enhanced support for complex applications and
                    advanced features.
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
