import React from "react";

const About = () => {
  return (
    <div id="about" style={{overflow:"hidden"}}>

    <div className="about pt-0 pt-md-5 pb-md-5 pb-0" >
      <div className="container pt-5 pb-5">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <h2 data-aos="fade-right" data-aos-duration="1000">About VOLREX</h2>
            <p data-aos="fade-right" data-aos-duration="1000">
            VOLREX NETWORK aspires to redefine blockchain’s role in global innovation by integrating AI, advanced consensus protocols, and scalable infrastructure. Our mission is to create a seamless, intelligent, and interconnected ecosystem where users, developers, and businesses collaborate and grow together.{" "}
            </p>
          </div>
          <div className="col-lg-6">
            <div className="text-center">
              <img data-aos="fade-left" data-aos-duration="1000" className="cus-img" src="\images\about.svg" alt="about" />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default About;
