import React from "react";

const Footer = () => {
  return (
    <div className="footer pt-0 pt-md-5 pb-md-5 pb-0" style={{overflow:"hidden"}}>
      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-lg-5"  data-aos="fade-right" data-aos-duration="1500">
          <img src="\images\logo.svg" alt="logo" />
          <br />
          <br />
          <br />
            <p>
            VOLREX NETWORK aspires to redefine blockchain’s role in global innovation by integrating AI, advanced consensus protocols, and scalable infrastructure. {" "}
            </p>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-3 mt-5"  data-aos="fade-left" data-aos-duration="1500">
            <label
              htmlFor="
            "
            >
              Quick Links
            </label>
            <ul>
              <li><a href="#hero">Home</a></li>
              <li><a href="#about">About VOLREX</a></li>
              <li><a href="#features">Features</a></li>

            </ul>
          </div>
          <div className="col-lg-3 mt-5"  data-aos="fade-left" data-aos-duration="1500">
            <label
              htmlFor="
            "
            >
              Quick Links
            </label>
            <ul>
            <li><a href="#community">Community</a> </li>
            <li><a href="#roadmap">Roadmap</a> </li>
              <li><a href="#join">Newsletter</a></li>
            </ul>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6">
            <p>Copyright © 2024. VOLREX NETWORK. </p>
            <p>Formerly known as SGC Blockchain. All rights reserved.</p>
          </div>
          <div className="col-lg-6">
            <div className="d-flex align-items-center justify-content-start justify-content-md-end  gap-4">
              <a href="#">
                <img src="\images\Instagram.svg" alt="instagram" />
              </a>
              <a href="#">
                <img src="\images\twitter.svg" alt="twitter" />
              </a>
              <a href="#">
                <img src="\images\Facebook.svg" alt="Facebook" />
              </a>
              <a href="">
                <img src="\images\linkdin.svg" alt="linkdin" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
