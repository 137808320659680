import React, { useState } from "react";
import Slider from "react-slick";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Fade } from "react-bootstrap";

const Roadmap = () => {
  const [activeTab, setActiveTab] = useState("home"); // Active Tab state

  var settings = {
    dots: true,
    infinite: false, // Infinite loop کو غیر فعال کریں
    autoplay: false,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200, // For screens smaller than 1200px
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992, // For screens smaller than 992px
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768, // For screens smaller than 768px
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576, // For screens smaller than 576px
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div id="roadmap" className="roadmap pt-5 pb-5">
      <div className="container pt-5 pb-5">
        <div className="row align-items-center">
          <div className="col-lg-8">
            <h2>Our Roadmap</h2>
            <p>
            Strategic milestones for the growth and innovation of VOLREX Network and Wallets.
            </p>
          </div>
          <div className="col-lg-4">
            <Tabs
              activeKey={activeTab}
              onSelect={(key) => setActiveTab(key)} // Update active tab
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="2025"></Tab>
              <Tab eventKey="profile" title="2026"></Tab>
              <Tab eventKey="contact" title="2027"></Tab>
            </Tabs>
          </div>
        </div>

        <div className="row pt-5">
          <div className="col-lg-12">

            <Tab.Container activeKey={activeTab}>
      
           
              <Tab.Content>
                <Tab.Pane eventKey="home">
                  <div class="h-border"></div>

                  <Slider {...settings}>
                  <div>
                      <div className="roadmap-box">
                        <div className="map-year">
                          <span>Q1 2025</span>
                        </div>
                        <div className="roadmap-content">
                          <ul>
                            <li>
                              {/* <img src="/images/check.png" alt="check" /> */}
                              <span>Launch of VOLREX Wallets</span>
                            </li>
                            <li>
                              <img src="/images/check.png" alt="check" />
                              <span>VOX Wallet will be a secure, user-friendly wallet for managing VOX
                              tokens, participating in staking, and interacting with dApps.</span>
                            </li>

                            <li>
                              <img src="/images/check.png" alt="check" />
                              <span>VOXDex Wallet will support decentralized trading and token management
                              on decentralized exchanges (DEXs).</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="roadmap-box">
                        <div className="map-year">
                          <span>Q2 2025</span>
                        </div>
                        <div className="roadmap-content">
                          <ul>
                            <li>
                              {/* <img src="/images/check.png" alt="check" /> */}
                              <span>Launch of VOLREX NETWORK Platform</span>
                            </li>
                           

                            <li>
                              <img src="/images/check.png" alt="check" />
                              <span>The official launch of the VOLREX NETWORK platform will mark a new era for
decentralized applications (dApps) and services, transitioning from SGC Blockchain to
the more robust and scalable VOLREX NETWORK.</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="roadmap-box">
                        <div className="map-year">
                          <span>Q3 2025</span>
                        </div>
                        <div className="roadmap-content">
                          <ul>
                            <li>
                              {/* <img src="/images/check.png" alt="check" /> */}
                              <span>Release of PoW and PoS Mining</span>
                            </li>
                            <li>
                              <img src="/images/check.png" alt="check" />
                              <span>Proof of Work (PoW): Mining for users who contribute computational power to
                              secure the network.</span>
                            </li>

                            <li>
                              <img src="/images/check.png" alt="check" />
                              <span>Proof of Stake (PoS): Community members can now participate by staking VOX
                              tokens to help validate transactions and earn rewards.</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="roadmap-box">
                        <div className="map-year">
                          <span>Q4 2025</span>
                        </div>
                        <div className="roadmap-content">
                          <ul>
                            <li>
                              {/* <img src="/images/check.png" alt="check" /> */}
                              <span>Introduction of Cross-Chain Interoperability via IBC</span>
                            </li>
                            <li>
                              <img src="/images/check.png" alt="check" />
                              <span>This ensures that VOLREX NETWORK can integrate and
                              communicate with other blockchain ecosystems, allowing for more flexibility and utility.</span>
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>

                  </Slider>
                </Tab.Pane>
                <Tab.Pane eventKey="profile" >
                  <div class="h-border"></div>

                  <Slider {...settings}>
                    <div>
                      <div className="roadmap-box">
                        <div className="map-year">
                          <span>Q1 2026</span>
                        </div>
                        <div className="roadmap-content">
                          <ul>
                            <li>
                              {/* <img src="/images/check.png" alt="check" /> */}
                              <span>Full Integration of AI into the Platform’s Functionality</span>
                            </li>
                            <li>
                              <img src="/images/check.png" alt="check" />
                              <span>dApp functionality through intelligent automation.</span>
                            </li>

                            <li>
                              <img src="/images/check.png" alt="check" />
                              <span>Predictive analytics for smarter decision-making.</span>
                            </li>
                            <li>
                              <img src="/images/check.png" alt="check" />
                              <span>Smart contract execution, improving efficiency and reducing operational costs.</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="roadmap-box">
                        <div className="map-year">
                          <span>Q2 2026</span>
                        </div>
                        <div className="roadmap-content">
                          <ul>
                            <li>
                              {/* <img src="/images/check.png" alt="check" /> */}
                              <span>Expansion of dApp Ecosystem with Industry Partnerships</span>
                            </li>
                            <li>
                              <img src="/images/check.png" alt="check" />
                              <span>AI-driven automation and scalable infrastructure with blockchain solutions for finance, healthcare, and supply chain sectors.</span>
                            </li>

                            <li>
                              {/* <img src="/images/check.png" alt="check" /> */}
                              <span>Upgraded VOLREX Wallets</span>
                            </li>
                            <li>
                              <img src="/images/check.png" alt="check" />
                              <span>The VOLREX Wallet update introduces seamless dApp integration, stronger security, and cross-chain transaction support.</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="roadmap-box">
                        <div className="map-year">
                          <span>Q3 2026</span>
                        </div>
                        <div className="roadmap-content">
                          <ul>
                            <li>
                              {/* <img src="/images/check.png" alt="check" /> */}
                              <span>Adoption of Lowest Gas Fee Model</span>
                            </li>
                            <li>
                              <img src="/images/check.png" alt="check" />
                              <span>VOLREX NETWORK's ultra-low gas fees, as low as $0.001 per transaction, aim to drive widespread adoption and usage.</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="roadmap-box">
                        <div className="map-year">
                          <span>Q4 2026</span>
                        </div>
                        <div className="roadmap-content">
                          <ul>
                            <li>
                              {/* <img src="/images/check.png" alt="check" /> */}
                              <span>Hybrid Consensus Model</span>
                            </li>
                            <li>
                              <img src="/images/check.png" alt="check" />
                              <span>A Hybrid PoW, PoS, and Physical Asset Verification model enhances network efficiency, security, and real-world asset trust.</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </Tab.Pane>
                <Tab.Pane eventKey="contact" >
                  <div class="h-border"></div>

                  <Slider {...settings}>
                    <div>
                      <div className="roadmap-box">
                        <div className="map-year">
                          <span>Q1 2027</span>
                        </div>
                        <div className="roadmap-content">
                          <ul>
                            <li>
                              {/* <img src="/images/check.png" alt="check" /> */}
                              <span>Global Expansion and Technological Refinement</span>
                            </li>
                            <li>
                              <img src="/images/check.png" alt="check" />
                              <span>VOLREX NETWORK sets the standard for scalable, secure, and interoperable blockchain solutions across industries worldwide.</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="roadmap-box">
                        <div className="map-year">
                          <span>Q2 2027</span>
                        </div>
                        <div className="roadmap-content">
                          <ul>
                          <li>
                              {/* <img src="/images/check.png" alt="check" /> */}
                              <span>Advancing Scalability and Transaction Speeds</span>
                            </li>
                            <li>
                              <img src="/images/check.png" alt="check" />
                              <span>Prioritizing faster transactions for seamless experiences and scalability to support growing global demand.</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="roadmap-box">
                        <div className="map-year">
                          <span>Q3 2027</span>
                        </div>
                        <div className="roadmap-content">
                          <ul>
                            <li>
                              {/* <img src="/images/check.png" alt="check" /> */}
                              <span>Innovating Hybrid Consensus Models</span>
                            </li>
                            <li>
                              <img src="/images/check.png" alt="check" />
                              <span>VOLREX NETWORK will integrate PoW for security, PoS for efficiency, and additional verification mechanisms to enhance sustainability and performance.</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="roadmap-box">
                        <div className="map-year">
                          <span>Q4 2027</span>
                        </div>
                        <div className="roadmap-content">
                          <ul>
                            <li>
                              {/* <img src="/images/check.png" alt="check" /> */}
                              <span>Further Expansion of VOLREX Wallets</span>
                            </li>
                            <li>
                              <img src="/images/check.png" alt="check" />
                              <span>The VOLREX Wallet will offer multi-currency support, DeFi platform integration, advanced staking options, and enhanced security with biometric authentication, multi-signature, and cold storage.</span>
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </Tab.Pane>
              </Tab.Content>
            
            </Tab.Container>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
