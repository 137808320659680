import React from "react";

const BuildCommunity = () => {
  return (
    <div id="community" style={{overflow:"hidden"}}>
      <div className="scale pt-0 pt-md-5 pb-md-5 pb-0">
        <div className="container pt-5 pb-5">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-4"  data-aos="fade-right" data-aos-duration="1500">
              <h2>Developer Community</h2>

              <p>
                
Blockchain's developer community is pioneering innovations in dApps, DeFi, smart contracts, and blockchain-based identity management, reshaping industries and creating new opportunities.
              </p>
              <div className="d-flex align-items-center buttons gap-3 mt-4">
                <button className="btn-bg gap-2">
                  Documentation (COMING SOON)
                  {/* <svg
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.792627 3.09414H13.1691L11.8898 1.84609V0.306836L15.218 3.75977V3.80137L11.8898 7.2543V5.71504L13.1483 4.48779H0.792627V3.09414Z"
                      fill="#255285"
                    />
                  </svg> */}
                </button>
                <button className="btn-outline">
                  <svg
                    width="39"
                    height="30"
                    viewBox="0 0 39 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M33.0797 2.46356C30.6199 1.31267 27.9898 0.476261 25.24 0C24.9023 0.61055 24.5078 1.43175 24.2358 2.08502C21.3128 1.64543 18.4166 1.64543 15.5473 2.08502C15.2753 1.43175 14.8718 0.61055 14.5311 0C11.7784 0.476261 9.14525 1.31575 6.68544 2.46965C1.72399 9.96706 0.379019 17.2782 1.0515 24.4856C4.3422 26.943 7.53128 28.4358 10.6665 29.4127C11.4407 28.3473 12.1311 27.2147 12.7258 26.0211C11.5931 25.5907 10.5081 25.0595 9.483 24.4429C9.75496 24.2414 10.021 24.0308 10.278 23.814C16.5306 26.7385 23.3242 26.7385 29.5021 23.814C29.7622 24.0308 30.0281 24.2414 30.2971 24.4429C29.269 25.0626 28.1811 25.5937 27.0483 26.0242C27.6431 27.2147 28.3305 28.3503 29.1076 29.4157C32.2459 28.4388 35.4379 26.9461 38.7286 24.4856C39.5177 16.1304 37.3807 8.88639 33.0797 2.46356ZM13.5777 20.0531C11.7007 20.0531 10.1614 18.3009 10.1614 16.1671C10.1614 14.0332 11.6678 12.2779 13.5777 12.2779C15.4876 12.2779 17.0268 14.0302 16.9939 16.1671C16.9969 18.3009 15.4876 20.0531 13.5777 20.0531ZM26.2025 20.0531C24.3255 20.0531 22.7862 18.3009 22.7862 16.1671C22.7862 14.0332 24.2926 12.2779 26.2025 12.2779C28.1123 12.2779 29.6516 14.0302 29.6187 16.1671C29.6187 18.3009 28.1123 20.0531 26.2025 20.0531Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="col-lg-6 mb-4" data-aos="fade-left" data-aos-duration="1500">
              <div className="text-center">
                <img className="cus-img" src="\images\BuildbyComunity.svg" alt="BuildbyComunity" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildCommunity;
