import React from "react";

const Scale = () => {
  return (
    <div id="scale" className="scale pt-0 pt-md-5 pb-md-5 pb-0" style={{overflow:"hidden"}}>
      <div className="container pt-5 pb-5">
        <div className="row align-items-center">
          <div className="col-lg-6"  data-aos="fade-right" data-aos-duration="1500">
            <h2>NETWORK UPGRADATION</h2>
            <div className="d-flex align-items-center gap-5 py-4">
              <div>
                <span>Near-Zero Fees</span>
                <h2>$0.001</h2>
              </div>
              {/* <div>
                <span>Near-zero fees</span>
                <h2>$0.001</h2>
              </div> */}
            </div>
            <p>
            The VOLREX Network upgrading to a hybrid consensus model, advanced scalability, AI-driven applications, near-zero fees, cross-chain interoperability, smart contracts, and decentralized governance.
            </p>
          </div>
          <div className="col-lg-6"  data-aos="fade-left" data-aos-duration="1500">
            <div className="text-center">
                <img className="cus-img" src="\images\scale.svg" alt="scale" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scale;
