import React from "react";

const Join = () => {
  return (
    <div id="join">
      <div className=" join">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>Contact Us</h2>
              <button className="btn-bg gap-3 mt-4">
              support@volrex.network
                {/* <svg
                  width="16"
                  height="8"
                  viewBox="0 0 16 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.792627 3.09414H13.1691L11.8898 1.84609V0.306836L15.218 3.75977V3.80137L11.8898 7.2543V5.71504L13.1483 4.48779H0.792627V3.09414Z"
                    fill="#255285"
                  />
                </svg> */}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Join;
