import React from "react";

const Community = () => {
  return (
    <div style={{overflow:"hidden"}}>
      <div className="scale pt-0 pt-md-5 pb-md-5 pb-0">
        <div className="container pt-5 pb-5">
          <div className="row align-items-center">
            <div className="col-lg-6 order-2 order-lg-1 mb-4"  data-aos="fade-right" data-aos-duration="1500">
              <div className="text-center">
                <img className="cus-img" src="\images\Community.svg" alt="Community" />
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 mb-4" data-aos="fade-left" data-aos-duration="1500">
              <h2>Blockchain’s biggest builder Community</h2>
              <p>
              Blockchain's biggest builder community is driving innovation across diverse sectors, from decentralized finance (DeFi) and supply chain management to real estate and healthcare. With advanced use cases like smart contracts, tokenization of assets, and NFT-based identity verification, blockchain is reshaping industries globally.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
