import React from "react";
import Slider from "react-slick";
const Hero = () => {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200, // For screens smaller than 1200px
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992, // For screens smaller than 992px
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // For screens smaller than 768px
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576, // For screens smaller than 576px
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div id="hero" className="hero pt-5 pb-3 px-3 px-md-0" style={{overflow:"hidden"}}>
      <div className="row pt-5 align-items-center h-100">
        <div className="col-lg-12" data-aos="fade-up" data-aos-duration="1500">
          <div>
            <h1 data-aos="fade-up" data-aos-duration="1500">
              REFORM, REBRANDING, AND TRANSITION 
            </h1>
            <h3 data-aos="fade-up" data-aos-duration="1500">
              SGC Blockchain to VOLREX NETWORK
            </h3>

            <div
              className="d-flex align-items-center justify-content-center gap-3 pt-5 buttons"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <button className="btn-bg gap-3">
                Documentation (COMING SOON)
                {/* <svg
                  width="16"
                  height="8"
                  viewBox="0 0 16 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.792627 3.09414H13.1691L11.8898 1.84609V0.306836L15.218 3.75977V3.80137L11.8898 7.2543V5.71504L13.1483 4.48779H0.792627V3.09414Z"
                    fill="#255285"
                  />
                </svg> */}
              </button>
              <button className="btn-outline gap-3">
               Builder Guide <span>(COMING SOON)</span>
                {/* <svg
                  width="16"
                  height="8"
                  viewBox="0 0 16 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.792627 3.09414H13.1691L11.8898 1.84609V0.306836L15.218 3.75977V3.80137L11.8898 7.2543V5.71504L13.1483 4.48779H0.792627V3.09414Z"
                    fill="white"
                  />
                </svg> */}
              </button>
            </div>
          </div>
          </div>

          {/* <Slider
            {...settings}
            className="pt-5"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <div className="text-center d-flex align-items-center justify-content-center">
              <img src="\images\vec-1.svg" alt="" />
            </div>
            <div className="text-center d-flex align-items-center justify-content-center">
              <img src="\images\vec-2.svg" alt="" />
            </div>
            <div className="text-center d-flex align-items-center justify-content-center">
              <img src="\images\vec-3.svg" alt="" />
            </div>
            <div className="text-center d-flex align-items-center justify-content-center">
              <img src="\images\vec-1.svg" alt="" />
            </div>
            <div className="text-center d-flex align-items-center justify-content-center">
              <img src="\images\vec-2.svg" alt="" />
            </div>
            <div className="text-center d-flex align-items-center justify-content-center">
              <img src="\images\vec-3.svg" alt="" />
            </div>
          </Slider> */}
      </div>
      <div className="waves-container">
        <svg
          className="wave wave1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="url(#gradient1)"
            d="M0,128L48,144C96,160,192,192,288,213.3C384,235,480,245,576,234.7C672,224,768,192,864,160C960,128,1056,96,1152,96C1248,96,1344,128,1392,144L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          >
            <defs>
              <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#ff6b6b" />
                <stop offset="100%" stopColor="#4facfe" />
              </linearGradient>
            </defs>
          </path>
        </svg>
        <svg
          className="wave wave2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="url(#gradient2)"
            d="M0,192L60,218.7C120,245,240,299,360,277.3C480,256,600,160,720,117.3C840,75,960,85,1080,122.7C1200,160,1320,224,1380,256L1440,288L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          >
            <defs>
              <linearGradient id="gradient2" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="#ffcc33" />
                <stop offset="100%" stopColor="#4facfe" />
              </linearGradient>
            </defs>
          </path>
        </svg>
      </div>
    </div>
  );
};

export default Hero;
