import React from "react";
import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
const Header = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="header">
        <div className="row">
          <div className="col-4 px-0">
            <div className="d-flex align-items-center w-100">
              {/* <a href="#home">ABOUT US</a> */}
              <a href="#about">About</a>
              <a href="#services">FEATURES</a>
            </div>
          </div>
          <div className="col-4  px-0">
            <a href="#home" className="img-cus  w-100">
              <img src="\images\logo.svg" alt="logo" />
            </a>
          </div>
          <div className="col-4 px-0">
            <div className="d-flex align-items-center  w-100">
              <a href="#scale">NETWORK</a>
              <a href="#contact" className="btn-header">
                CONTACT US
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-header">
        <a href="#home" className="img-cus ">
          <img src="\images\logo.svg" alt="logo" />
        </a>
        <div onClick={handleShow} style={{cursor:"pointer"}}>
          <img src="\images\Menu.svg" alt="Menu" />
        </div>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <img src="\images\volrex.png" alt="volrex" />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul>
              <li>
                {" "}
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#project">Projects</a>
              </li>
              <li>
                <a href="#blog">Blog</a>
              </li>
              <li>
                <a href="contact" className="btn-header">
                  Contact us
                </a>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default Header;
